import React, { useContext, useState, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";

//Material Ui
import {
  AppBar,
  Divider,
  Fade,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Backdrop,
  makeStyles
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import moment from "moment";
// import { orderData, contextMenuItems, orderGrid } from "../../assets/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import ConfirmModal from "./ConfirmModel";
import CancelModel from "./CancelModel";
import DeleteModel from "./DeleteModel";
import { contextMenuItems, orderGrid, orderData } from "./config/OrdersConfig";
import { BsFillPencilFill } from "react-icons/bs";
import StateContext from "../../contexts/ContextProvider";
import axios from "axios";
import { Button, CircularProgress, Toolbar, Typography } from "@mui/material";
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";
import "./vieworder.css";
import PaymentModal from "./PaymentModal";

 
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
  },
  cell: {
    padding: "8px",
    textAlign: "start",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px 15px 15px 8px",
    textAlign: "start",
  },
}));

const Orders = () => {
  const { merId, setMerId } = useContext(StateContext);
  const classes = useStyles();
  const [newOrderData, setNewOrderData] = useState([]);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const { value } = useContext(StateContext);
  const { setValue } = useContext(StateContext);
  const [loading, setLoading] = useState(false);
  const [headerValue, setHeaderValue] = useState("Order Action");
  const [headerValueAction, setHeaderValueAction] = useState("Actions");
  const [searchQuery, setSearchQuery] = useState("");

  const random = 190;

  // const filteredData = data?.data?.filter((item) => {
  //   return item.itemName.toLowerCase().includes(searchQuery.toLowerCase());
  // });

  console.log(newOrderData, "sdddsdsfsdfscs");
  const orderForStatus = newOrderData.map((status) => {
    return status.orderStatus;
  });
  console.log(newOrderData, orderForStatus[0], "sdddsdsfsdfscs");

  async function fetchOrders(status) {
    setLoading(true);
    const selectedStatus = status || "PENDING";
    if (merId) {
      console.log("merchant ID", merId);
    } else {
      console.log(sessionStorage.getItem("id"));
    }
    if (!merId) {
      setMerId(sessionStorage.getItem("id"));
    }
    await axios
      .get(
         `https://api.orbitmart.co.in/api/v1/order/user/${merId}/${selectedStatus}` //status filter api like pending orders, processing order, and many other
       // `https://api.orbitmart.co.in/api/v1/order` // this is for all orders without the filter applied

      )
      .then((res) => {
        let data = res.data.data.reverse();
        setData(data);
        const pendingItems = data.filter( 
          (item) => item.orderStatus === selectedStatus
        );
        setNewOrderData(pendingItems);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  }

  useEffect(() => {
    if (merId) {
      fetchOrders();
    }
  }, [value, merId]);

  console.log("full Data", data);
  const getAmount = (or) => {
    return (
      or.totalPrice -
      or.productDetails
        .map((pr) => {
          if (pr?.productId?.discountType == "FIXED") {
            return parseInt(pr.productId.customerDiscount);
          } else {
            return (
              (parseInt(pr.productId?.itemPrice) *
                parseInt(
                  pr.productId?.customerDiscount !== ""
                    ? pr.productId?.customerDiscount
                    : 0
                )) /
              100
            );
          }
        })
        .reduce((a, b) => a + b) +
      (or.totalPrice * parseInt(or.productDetails[0].productId?.gst)) / 100 +
      20 +
      or?.productDetails
        ?.map((pr) => {
          return parseInt(pr?.productId?.orbitMartCommission);
        })
        .reduce((a, b) => a + b)
    ).toFixed(0);
  };

  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const toolbarOptions = ["search"];

  const navigate = useNavigate();

  function handleOrderStatus(e, action) {
    setActiveButton({
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      six: false,
      seven: false,
    });

    if (action == "pending") {
      const pendingItems = data.filter(
        (item) => item.orderStatus === "PENDING"
      );
      setNewOrderData(pendingItems);
      setActiveButton({ first: true });
    } else if (action == "processing") {
      const processingItems = data.filter(
        (item) => item.orderStatus === "PROCESSING"
      );
      setNewOrderData(processingItems);
      setActiveButton({ second: true });
    } else if (action == "packaging") {
      const packagingItems = data.filter(
        (item) => item.orderStatus === "PACKAGING"
      );
      setNewOrderData(packagingItems);
      setActiveButton({ third: true });
    } else if (action == "shipping") {
      const shippingItems = data.filter(
        (item) => item.orderStatus === "SHIPPING"
      );
      setNewOrderData(shippingItems);
      setActiveButton({ fourth: true });
    } else if (action == "delivered") {
      const deliveredItems = data.filter(
        (item) => item.orderStatus === "DELIVERED"
      );
      setNewOrderData(deliveredItems);
      setActiveButton({ fifth: true });
    } else if (action === "completed") {
      const deliveredItems = data.filter(
        (item) => item.orderStatus === "COMPLETED"
      );
      setNewOrderData(deliveredItems);
      setActiveButton({ seven: true });
    } else {
      const rejectedItems = data.filter(
        (item) =>
          item.orderStatus === "REJECTED" || item.orderStatus === "CANCELLED"
      );
      setNewOrderData(rejectedItems);
      setActiveButton({ six: true });
    }
  }

  const addPage = () => {
    navigate("/manageItem/add");
  };
  const [activeButton, setActiveButton] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    six: false,
    seven: false,
    eight: false,
  });

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const CustomPaginationActions = (props) => {
    const { count, page, rowsPerPage, onChangePage } = props;
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };

  const gridOrderStatus = (props) => (
    <button
      type="button"
      style={{ background: "#ff5c8e" }}
      className="text-white py-1 px-2 capitalize rounded-2xl text-md"
    >
      {props.orderStatus}
    </button>
  );

  const gridConfirm = (props) => (
    <div class="flex space-x-2">
      {console.log(props)}
      <h6
        style={{ color: "#03c9d7", display: "flex" }}
        class="font-medium leading-tight mt-0 mb-2 "
      >
        {props.orderStatus == "PENDING" && <ConfirmModal props={props} />}
        {props.orderStatus == "DELIVERED" && <PaymentModal props={props} />}
      </h6>
    </div>
  );

  const onClose = () => {
    fetchOrders();
  };

  // const gridOrderEdit = (itemId) => (
  //   <div style={{ display: "flex", justifyContent: "space-evenly" }}>
  //     <h1
  //       style={{ color: "#03c9d7", display: "flex" }}
  //       className="font-small leading-tight text-xl mt-0 mb-2 "
  //     >
  //       <Link to={`/Order/view/${itemId._id}`}>
  //         <FiEye />
  //       </Link>
  //     </h1>
  //     <h1
  //       style={{ color: "#ff5c8e", display: "flex" }}
  //       className="font-small leading-tight text-xl mt-0 mb-2 "
  //     >
  //       {itemId.orderStatus == "REJECTED" ||
  //       itemId.orderStatus == "CANCELLED" ||
  //       itemId.orderStatus == "COMPLETED" ? (
  //         <DeleteModel itemId={itemId} onClose={onClose} />
  //       ) : null}
  //     </h1>
  //   </div>
  // );

 
  const gridOrderEdit = (itemId) => (
    <div className="flex items-center gap-2">
      <h1
        style={{ color: "#03c9d7", display: "flex" }}
        className="font-medium leading-tight text-xl mt-0 mb-2 "
      >
        <Link to={`/Order/view/${itemId._id}`}>
          <FiEye />
        </Link>
      </h1>
      <h1
        style={{ color: "#ff5c8e", display: "flex" }}
        className="font-medium leading-tight text-xl mt-0 mb-2 "
      >
        {itemId.orderStatus == "REJECTED" ||
        itemId.orderStatus == "CANCELLED" ||
        itemId.orderStatus == "COMPLETED" ? (
          <DeleteModel itemId={itemId} onClose={onClose} />
        ) : null}
      </h1>
    </div>
  );

  const dateFormat = (date) => {
    let dates = new Date(date);
    let formattedDate = dates.toLocaleString();
    return formattedDate;
  };

  const filteredData = newOrderData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );
  {
    console.log({ filteredData });
  }

  function handleOrderStatus(e, action) {
    // setColor('#D6D4D3')
    // var button=document.getElementById('mybutton')
    setActiveButton({
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      six: false,
      seven: false,
    });

    if (action == "pending") {
      const pendingItems = data.filter(
        (item) => item.orderStatus === "PENDING"
      );
      setNewOrderData(pendingItems);
      setActiveButton({ first: true });
      setHeaderValue("Order Action");
      setHeaderValueAction("Actions");
      // setColor(rgb(0, 60, 126))
    } else if (action == "processing") {
      const processingItems = data.filter(
        (item) => item.orderStatus === "PROCESSING"
      );
      setNewOrderData(processingItems);
      setActiveButton({ second: true });
      setHeaderValue("");
      setHeaderValueAction("Actions");
      // setColor('rgb(0, 60, 126)')
    } else if (action == "packaging") {
      const packagingItems = data.filter(
        (item) => item.orderStatus === "PACKAGING"
      );
      setNewOrderData(packagingItems);
      setActiveButton({ third: true });
      setHeaderValue("");
      setHeaderValueAction("Actions");
      // setColor('rgb(0, 60, 126)')
    } else if (action == "shipping") {
      const shippingItems = data.filter(
        (item) => item.orderStatus === "SHIPPING"
      );
      setNewOrderData(shippingItems);
      setActiveButton({ fourth: true });
      setHeaderValue("");
      setHeaderValueAction("Actions");
      // setColor('rgb(0, 60, 126)')
    } else if (action == "delivered") {
      const deliveredItems = data.filter(
        (item) => item.orderStatus === "DELIVERED"
      );
      setNewOrderData(deliveredItems);
      setActiveButton({ fifth: true });
      setHeaderValue("Order Action");
      setHeaderValueAction("Actions");
      // setColor('rgb(0, 60, 126)')
    } else if (action == "completed") {
      const deliveredItems = data.filter(
        (item) => item.orderStatus === "COMPLETED"
      );
      setNewOrderData(deliveredItems);
      setActiveButton({ seven: true });
      setHeaderValue("");
      setHeaderValueAction("Actions");
    } else if (action === "cancelled") {
      const cancelledItems = data.filter(item => item.orderStatus === "CANCELLED")
      setNewOrderData(cancelledItems);
      setActiveButton({ eight: true });
      setHeaderValue("");
      setHeaderValueAction("Actions");
    } else {
      const rejectedItems = data.filter(
        (item) =>
          item.orderStatus === "REJECTED"
      );
      setNewOrderData(rejectedItems);
      setActiveButton({ six: true });
      setHeaderValue("");
      setHeaderValueAction("Actions");
      // setColor('rgb(0, 60, 126)')
      // console.log('rej')
    }
    // console.log(filteredItems)
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const changeToFirstPage = () => {
    setLoading(true);
    setCurrentPage(0);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToPrevPage = () => {
    setLoading(true);
    setCurrentPage(currentPage - 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToNextPage = () => {
    setLoading(true);
    setCurrentPage(currentPage + 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToLastPage = () => {
    setLoading(true);
    setCurrentPage(Math.ceil(newOrderData?.length / pageSize) - 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleOpen = () => {
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  console.log("current Page", currentPage);

  console.log("calc", Math.ceil(filteredData?.length / pageSize));

  const priceShow = (itemValue) => {
    console.log({ itemValue });
    let totalAmount = 0;
    for (let i = 0; i < itemValue.productDetails.length; i++) {
      let quantityPrice =
        itemValue.productDetails[i].productPrice *
        itemValue.productDetails[i].quantity;
      totalAmount = totalAmount + quantityPrice;
    }
    let totalAmountFinal =
      totalAmount + itemValue.totalGST - itemValue.totalDiscount;
    // let totalAmountFinal;
    // if (itemValue.totalGST !== null && itemValue.totalDiscount !== null) {
    //   totalAmountFinal =
    //     totalAmount + itemValue.totalGST - itemValue.totalDiscount;
    // }
    console.log(
      "sssssssssssssssssssssssssssssss",
      totalAmountFinal,
      itemValue.totalGST,
      itemValue.totalDiscount
    );
    return totalAmountFinal.toFixed(0);
  };

  const blueColor = "#003c7e";
  const containedButtonStyle = {
    color: "white",
    backgroundColor: blueColor,
    borderColor: blueColor,
  };
  const outlinedButtonStyle = {
    color: blueColor,
    borderColor: blueColor,
  };

  console.log("cure Page", currentPage);
  console.log("all orders", newOrderData.length);
  console.log("xyz", filteredData?.length);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <AppBar
        position="static"
        style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Orders ({newOrderData?.length})</Typography>
          <span
            class="flex space-x-2 m-4 justify-end"
            style={{ background: "white", borderRadius: "6px" }}
          >
            <input
              style={{
                padding: "4px 10px",
                outline: "none",
                borderRadius: "6px 0 0 6px",
                color: "black",
              }}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <BiSearch
              style={{
                fontSize: "25px",
                color: "gray",
                cursor: "pointer",
                margin: "auto",
                paddingRight: "5px",
              }}
            />{" "}
            {/*  this is the search icon */}
          </span>
        </Toolbar>
      </AppBar>
      <div
        className="hide-scrollbar"
        style={{
          display: "flex",
          // flexWrap: "wrap",
          justifyContent: "start",
          // alignItems: "center",
          marginTop: "20px",
          overflowX: "auto",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": {
            display: "none",
            // width: '0.1em', // Adjust the width as needed
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent", // Hide the thumb
          },
        }}
      >
        <Button
          variant={activeButton.first ? "contained" : "outlined"}
          onClick={(e) => {
            handleOrderStatus(e, "pending"), setCurrentPage(0);
            fetchOrders("PENDING");
          }}
          style={{
            ...(activeButton.first
              ? containedButtonStyle
              : outlinedButtonStyle),
            margin: "7px",
            fontSize: "1vmax",
          }}
        >
          Pending
        </Button>
        <Button
          variant={activeButton.second ? "contained" : "outlined"}
          onClick={(e) => {
            handleOrderStatus(e, "processing"), setCurrentPage(0);
            fetchOrders("PROCESSING");
          }}
          style={{
            ...(activeButton.second
              ? containedButtonStyle
              : outlinedButtonStyle),
            margin: "7px",
            // fontSize: "1vw"
            fontSize: "1vmax",
          }}
        >
          Processing
        </Button>
        <Button
          variant={activeButton.third ? "contained" : "outlined"}
          onClick={(e) => {
            handleOrderStatus(e, "packaging"), setCurrentPage(0);
            fetchOrders("PACKAGING");
          }}
          style={{
            ...(activeButton.third
              ? containedButtonStyle
              : outlinedButtonStyle),
            margin: "7px",
            fontSize: "1vw",
            fontSize: "1vmax",
          }}
        >
          Packaging
        </Button>
        <Button
          variant={activeButton.fourth ? "contained" : "outlined"}
          onClick={(e) => {
            handleOrderStatus(e, "shipping"), setCurrentPage(0);
            fetchOrders("SHIPPING");
          }}
          style={{
            ...(activeButton.fourth
              ? containedButtonStyle
              : outlinedButtonStyle),
            margin: "7px",
            fontSize: "1vmax",
          }}
        >
          Shipping
        </Button>
        <Button
          variant={activeButton.fifth ? "contained" : "outlined"}
          onClick={(e) => {
            handleOrderStatus(e, "delivered"), setCurrentPage(0);
            fetchOrders("DELIVERED");
          }}
          style={{
            ...(activeButton.fifth
              ? containedButtonStyle
              : outlinedButtonStyle),
            margin: "7px",
            fontSize: "1vmax",
          }}
        >
          Payment
        </Button>
        <Button
          variant={activeButton.seven ? "contained" : "outlined"}
          onClick={(e) => {
            handleOrderStatus(e, "completed");
            setCurrentPage(0);
            fetchOrders("COMPLETED");
          }}
          style={{
            ...(activeButton.seven
              ? containedButtonStyle
              : outlinedButtonStyle),
            margin: "7px",
            fontSize: "1vmax",
          }}
        >
          Completed
        </Button>

        <Button
          variant={activeButton.six ? "contained" : "outlined"}
          onClick={(e) => {
            handleOrderStatus(e, "rejected"), setCurrentPage(0);
            fetchOrders("REJECTED");
          }}
          style={{
            ...(activeButton.six ? containedButtonStyle : outlinedButtonStyle),
            margin: "7px",
            fontSize: "1vmax",
            width: "auto",
          }}
        >
          Rejected
        </Button>
        <Button
          variant={activeButton.eight ? "contained" : "outlined"}
          onClick={(e) => {
            handleOrderStatus(e, "cancelled"), setCurrentPage(0);
            fetchOrders("CANCELLED");
          }}
          style={{
            ...(activeButton.eight ? containedButtonStyle : outlinedButtonStyle),
            margin: "7px",
            fontSize: "1vmax",
            width: "auto",
          }}
        >
          Cancelled
        </Button>
      </div>
      {console.log({ filteredData })}
      <div style={{ marginTop: "10px" }}></div>
      {/* item?.orderStatus === "DELIVERED" || item?.orderStatus === "PENDING" */}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>Order No</TableCell>
              <TableCell className={classes.cellRow}>Order Date</TableCell>
              <TableCell className={classes.cellRow}>Order Amount</TableCell>
              <TableCell className={classes.cellRow}>Order Status</TableCell>
              <TableCell className={classes.cellRow}>Client Name</TableCell>
              {headerValue == "" ? null : <TableCell className={classes.cellRow}>{headerValue}</TableCell>}
              <TableCell className={classes.cellRow}>{headerValueAction}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <TableRow key={item._id}>
                  <TableCell className={classes.cell}>{item.orderNo}</TableCell>
                  <TableCell className={classes.cell}>{dateFormat(item.createdAt)}</TableCell>
                  <TableCell className={classes.cell}>
                    {/* {item.totalPrice - item.totalDeliveryCharge} */}
                    {item.totalPrice}
                  </TableCell>
                  {console.log(item, ".....", priceShow(item))}
                  <TableCell className={classes.cell}>{gridOrderStatus(item)}</TableCell>
                  {item?.userId?.firstName ? (
                    <TableCell className={classes.cell}>
                      {item.userId.firstName + " " + item.userId.lastName}
                    </TableCell>
                  ) : (
                    <TableCell className={classes.cell}></TableCell>
                  )}
                  {item?.orderStatus === "DELIVERED" ||
                  item?.orderStatus === "PENDING" ? (
                    <TableCell className={classes.cell}>{gridConfirm(item)}</TableCell>
                  ) : null}
                  <TableCell className={classes.cell}>{gridOrderEdit(item)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {console.log({ filteredData })}
        {newOrderData.length > 0 ? (
          <TablePagination
            count={newOrderData.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onChangePage={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
            ActionsComponent={() => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  onClick={changeToFirstPage}
                  disabled={currentPage === 0}
                >
                  <FirstPageOutlined />
                </Button>
                <Button onClick={changeToPrevPage} disabled={currentPage === 0}>
                  <NavigateBefore />
                </Button>
                <span className="myPage">{currentPage + 1}</span>
                <Button
                  onClick={changeToNextPage}
                  disabled={
                    currentPage >=
                    Math.ceil(newOrderData?.length / pageSize) - 1
                  }
                >
                  <NavigateNext />
                </Button>
                <Button
                  onClick={changeToLastPage}
                  disabled={
                    currentPage >=
                    Math.ceil(newOrderData?.length / pageSize) - 1
                  }
                >
                  <LastPageOutlined />
                </Button>
              </div>
            )}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};
export default Orders;
